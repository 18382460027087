import * as Sentry from '@sentry/browser';
import {User} from '@sentry/browser';
import {BrowserTracing} from '@sentry/tracing';
import 'app/extension/_extensions';

const sentryDsn = APP_CONFIG.common.sentryDsn;

if ('prod' === APP_CONFIG.common.environment && typeof sentryDsn === 'string' && sentryDsn.trim() !== '') {
    Sentry.init({
        dsn:sentryDsn,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
    Sentry.setTag('request_id', APP_CONFIG.common.sentryRequestId);
    if (!String.isNullOrWhiteSpace(APP_CONFIG.common.route)) {
        Sentry.setTag('route', APP_CONFIG.common.route);
    }
    const user = APP_CONFIG.common.user;
    const userData: User = {};
    if (user !== null) {
        userData.id = user.id.toString();
        userData.email = user.email;
        userData.username = user.email;
    }
    const clientIp = APP_CONFIG.common.clientIp;
    if (!String.isNullOrWhiteSpace(clientIp)) {
        userData.ip_address = clientIp as string;
    }
    if (!$.isEmptyObject(userData)) {
        Sentry.setUser(userData);
    }
}
